.popup-overlay {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(0, 0, 0, 0.5); /* Dim the background */
    display: flex;
    align-items: center; /* Center vertically */
    justify-content: center; /* Center horizontally */
    z-index: 1000; /* Make sure it's above other content */
  }
  
  .popup-container {
    background: white;
    padding: 20px;
    border-radius: 5px;
    max-width: 500px; /* or any other max-width */
    width: 90%; /* responsive width */
    z-index: 1001; /* Above the overlay */
  }
  
  .close-btn {
    padding: 8px 15px;
    background-color: #ff5f5f;
    color: white;
    border: none;
    border-radius: 4px;
    font-weight: bold;
    cursor: pointer;
    outline: none;
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.2);
    transition: background-color 0.3s ease;
  }
  
  .close-btn:hover {
    background-color: #ff3f3f;
  }
  