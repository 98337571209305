.contact-form {
    max-width: 400px; /* Adjust the width as needed */
    background: #fff; /* White background */
    padding: 20px;
    border-radius: 8px; /* Rounded corners for the form */
    box-shadow: 0 4px 8px rgba(0,0,0,0.1); /* Box shadow for the form */
}

.contact-form .form-group {
    position: relative;
    margin-bottom: 1rem; /* Spacing between fields */
}

.contact-form .form-group::before {
    position: absolute; /* Absolute position for the icon */
    left: 15px; /* Adjust as needed to position the icon to the left of the input */
    top: 50%; /* Center vertically */
    transform: translateY(-50%); /* Ensure the icon is centered vertically */
    color: #888; /* Icon color */
    /* ... other styles ... */
}

.contact-form input[type="text"],
.contact-form input[type="email"],
.contact-form input[type="tel"] {
    width: 100%;
    padding: 10px 40px 10px 50px; /* Padding for text inside input, adjust the left and right padding as needed for icons */
    border: 1px solid #ddd; /* Light gray border */
    border-radius: 20px; /* Rounded borders */
    background: #f9f9f9; /* Lighter background for the input fields */
    transition: border 0.3s ease;
}

.contact-form input[type="text"]:focus,
.contact-form input[type="email"]:focus,
.contact-form input[type="tel"]:focus {
    border-color: #ccc; /* Border color on focus */
    outline: none; /* Remove the default focus outline */
}

.contact-form .main-btn {
    width: 100%;
    padding: 10px; /* Padding inside the button */
    border: none; /* Remove border */
    background-color: rgb(20, 20, 207); /* Green background */
    color: white; /* White text */
    border-radius: 20px; /* Rounded borders */
    font-size: 16px; /* Font size for the button text */
    cursor: pointer; /* Cursor to pointer to signify it's clickable */
    transition: background-color 0.3s ease;
}

.contact-form .main-btn:hover {
    background-color: #45a049; /* Darker green on hover */
}

/* Add the icons using pseudo-elements */
.contact-form .form-group:nth-child(1)::before {
    content: "\f007"; /* FontAwesome icon unicode */
    font-family: 'FontAwesome'; /* Set the icon font family */
    position: absolute;
    left: 15px;
    top: 50%;
    transform: translateY(-50%);
    color: #888; /* Icon color */
}

.contact-form .form-group:nth-child(2)::before {
    content: "\f0e0"; /* FontAwesome icon unicode */
    font-family: 'FontAwesome';
    position: absolute;
    left: 15px;
    top: 50%;
    transform: translateY(-50%);
    color: #888;
}

.contact-form .form-group:nth-child(3)::before {
    content: "\f095"; /* FontAwesome icon unicode */
    font-family: 'FontAwesome';
    position: absolute;
    left: 15px;
    top: 50%;
    transform: translateY(-50%);
    color: #888;
}

/* Adjust margin for the submit button */
.contact-form .main-btn.mt-4 {
    margin-top: 1.5rem;
}

.contact-form .form-heading {
    font-size: 1.5rem; /* Adjust the font size as needed */
    color: #333; /* Dark gray color */
    margin-bottom: 1rem; /* Spacing below the heading */
    text-align: center; /* Center the heading */
}

.contact-form .form-group.name-icon::before {
    content: "\f007"; /* Correct unicode for user icon in FontAwesome 5 */
    font-family: 'Font Awesome 5 Free'; /* Specify the correct font family */
    font-weight: 900; /* Use 900 for solid icons */
    /* ... other styles remain the same ... */
}

.contact-form .form-group.email-icon::before {
    content: "\f0e0"; /* Correct unicode for envelope icon in FontAwesome 5 */
    font-family: 'Font Awesome 5 Free';
    font-weight: 900;
    /* ... other styles remain the same ... */
}

.contact-form .form-group.phone-icon::before {
    content: "\f095"; /* Correct unicode for phone icon in FontAwesome 5 */
    font-family: 'Font Awesome 5 Free';
    font-weight: 900;
    /* ... other styles remain the same ... */
}