           /* TABLE OF CONTENTS */
/* ------------------
1. General
2. Navbar
3. Banner
4. About
5. Services
6. Team
6. Gallery
7. Testimonial
8. Blog
9. Footer
-------------------- */

/* 1 General */
@import url('https://fonts.googleapis.com/css2?family=Josefin+Sans:wght@100;200;300;400;500;600;700&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Roboto+Slab:wght@100;200;300;400;500;600;700;900&display=swap');

* {
    box-sizing: border-box;
    margin: 0;
    padding: 0;
}

:root {
    /* Background Color */
    --primary-color: #004caa;
    --secondary-color: #fc5185;
    --bg-light-blue: #3672bc;
    --bg-dark: #666;
    --bg-white: #fff;

    /* Text Style */
    --primary-font: 'Roboto Slab', serif;
    --secondary-font: 'Josefin Sans', sans-serif;
    --primary-text: #004caa;
    --secondary-text: #fc5185;
    --text-white: #fff;
    --text-black: #222;
    --text-gray: #666;
    --text-light: #ddd;
}

body {
    font-family: var(--primary-font);
    background-color: var(--bg-white);
}

a {
    text-decoration: none;
}

::-webkit-scrollbar {
    width: .375rem;
}

::-webkit-scrollbar-track {
    background: var(--bg-light-blue);
}

::-webkit-scrollbar-thumb {
    background: var(--secondary-color);
}

.wrapper{
    padding:6.25rem 0;
}
.main-btn{
    display: inline-block;
    border:.0625rem solid var(--bg-white);
    color:var(--text-white);
    text-align: center;
    padding:.75rem 1.375rem;
    font-size: 1rem;
    background:transparent;
    text-transform: capitalize;
    border-radius: .1875rem;
    transition:all 0.3s ease-in-out;
}
.main-btn:hover{
    background-color:var(--secondary-color);
    border-color:var(--secondary-color);
    color:var(--text-white);
}
.fill-btn{
    background-color:var(--secondary-color);
    border-color:var(--secondary-color);
    color:var(--text-white);
}
.fill-btn:hover{
    background-color:var(--bg-white);
    border-color:var(--bg-white);
    color:var(--text-black)
}
h1{
    font-size: 3.875rem;
    margin-bottom:1.25rem;
    font-weight: 600;
    color:var(--text-white);
    font-family: var(--secondary-font);
}
h2{
    font-size: 2.875rem; 
    font-weight: 600;
    color:var(--text-white);
    font-family: var(--secondary-font);
}
h3{
    font-size: 2.25rem; 
    font-weight: 600;
    color:var(--text-white);
    font-family: var(--secondary-font);
}
h4{
    font-size: 1.5rem; 
    font-weight: 600;
    line-height: 2.25rem;
    color:var(--text-black);
    font-family: var(--secondary-font);
}
h5{
    font-size: 1.5rem; 
    line-height: 1.2;
    font-weight: 500;
    color:var(--primary-text);
    margin-bottom:.9375rem;
    font-family: var(--secondary-font);
}
h6{
    font-size: .875rem; 
    font-weight: 300;
    color:var(--text-black);
    margin-bottom:.9375rem;
    text-transform: uppercase;
    font-family: var(--secondary-font);
}
p{
    font-size: 1rem;
    color:var(--text-gray);
    line-height: 1.625rem;
} 
.card{
    border-radius: .625rem;
    background-color:var(--bg-white);
    box-shadow: .5084rem 1.1419rem 2.5rem 0 rgb(0 0 0 / 8%);
    padding:3.75rem 3.125rem 4.6875rem;
    -webkit-transition:all .4s;
    transition:all .4s;
    border:0;
}
@keyframes movebounce{
    0%{
        -webkit-transform:translateY(0);
        transform:translateY(0);
    }
    50%{
        -webkit-transform:translateY(1.25rem);
        transform:translateY(1.25rem);
    }
    100%{
        -webkit-transform:translateY(0);
        transform:translateY(0);
    }
}

/* 2 Navbar */ 
.header_wrapper .navbar{
    padding:.9375rem 0;
    background-color:transparent;
    transition:0.3s ease-in-out;
}
.header_wrapper .navbar-toggler,
.header_wrapper .navbar-toggler:focus{
    border:0;
    box-shadow: none;
    color:var(--text-black);
    line-height: 2;
}

.header_wrapper .nav-item{
    margin:0 .625rem;
}
.header_wrapper .nav-item .nav-link{
    font-size: 1.125rem;
    font-weight: 400;
    color:var(--primary-text);
    display:inline-block;
    font-family: var(--secondary-font);
}
.header_wrapper .nav-item .nav-link:hover,
.header_wrapper .nav-item .nav-link:focus,
.header_wrapper .nav-item .nav-link.active{
    color:var(--secondary-text);
}
.navbar.header-scrolled{
    position:fixed;
    top:0;
    left:0;
    width:100%;
    z-index:999;
    background-color:var(--bg-white);
    box-shadow: .5084rem 1.1419rem 2.5rem 0 rgb(0 0 0 / 8%);
    transition:0.3s ease-in-out;
}

/* 3 Banner */
.banner_wrapper{
    background-image:url('./images/top-banner-img/Top-banner.jpg');
    background-repeat: no-repeat;
    background-position: center center;
    background-size: cover;
}
.banner_wrapper p{
    max-width: 80%;
    color:var(--text-white);
}

.banner_wrapper .top-right-sec{
    position:relative;
    background-image:url('./images/top-banner-img/top-right-bg.png');
    background-repeat: no-repeat;
    background-position: bottom;
    background-size: contain;
    display: flex;
    align-items: flex-end;
    justify-content:center;
}
.banner_wrapper .animate-img{
    position:absolute;
    bottom:0;
    left:50%;
    -webkit-transform:translateX(-50%);
    transform:translateX(-50%);
    z-index:22;
}
.banner_wrapper .animate-img .aimg1{
    left:-5.6875rem;
    position:absolute;
    -webkit-animation-name:movebounce;
    animation-name:movebounce;
    -webkit-animation-delay: 100ms;
    animation-delay: 100ms;
    -webkit-animation-iteration-count: infinite;
    animation-iteration-count: infinite;
    -webkit-animation-timing-function: ease-in-out;
    animation-timing-function: ease-in-out;
    -webkit-animation-duration: 2s;
    animation-duration: 2s;
    -webkit-animation-direction: alternate;
    animation-direction: alternate;
}
.banner_wrapper .animate-img .aimg2{
    margin-left:5.5rem; 
    -webkit-animation-name:movebounce;
    animation-name:movebounce;
    -webkit-animation-delay: 200ms;
    animation-delay: 200ms;
    -webkit-animation-iteration-count: infinite;
    animation-iteration-count: infinite;
    -webkit-animation-timing-function: ease-in-out;
    animation-timing-function: ease-in-out;
    -webkit-animation-duration: 2.5s;
    animation-duration: 2.5s;
    -webkit-animation-direction: alternate;
    animation-direction: alternate;
}

.home .card:hover{
    -webkit-transform:translateY(-0.625rem);
    transform:translateY(-0.625rem);
}
.home .icon-box{
    text-align: center;
    margin: 0 auto 2.5rem;
}

/* 4 About */
.about_wrapper h2{
    color:var(--text-black);
}
.about_wrapper .card{
    width:80%;
    flex-direction: row;
    justify-content: space-between;
    flex-wrap: wrap;
}
.about_wrapper .about-clinic{
    flex:0 0 auto;
    width:50%;
}
.about_wrapper .about-clinic:nth-child(1),
.about_wrapper .about-clinic:nth-child(2){
    margin-bottom:2.8125rem;
}
.about_wrapper .about-clinic:nth-child(2),
.about_wrapper .about-clinic:nth-child(4){
    padding-left:3.75rem;
}
.about_wrapper .line-1,
.about_wrapper .line-2{
    content:'';
    position:absolute;
    left:50%;
    top:50%;
    -webkit-transform:translate(-50%,-50%);
    transform:translate(-50%,-50%);
    width:.0313rem;
    height:100%;
    background:var(--bg-dark);
}
.about_wrapper .line-2{
    width:100%; 
    height:.0625rem;
}
.about_wrapper .about-animate{
    position:absolute;
    left:-2.5rem;
    bottom:0;
    -webkit-animation:movebounce 3s linear infinite;
    animation:movebounce 3s linear infinite;
}
 
/* 5 Services */
.services_wrapper {
    background-color:var(--primary-color);
}
.services_wrapper .card{
    background-color:transparent;
    border:.0625rem solid var(--bg-light-blue);
    box-shadow: none;
    padding:2.5rem;
}
.services_wrapper .card:hover{
    background:var(--bg-light-blue);
    cursor:cell;
    border-color:transparent;
}
.services_wrapper .card h4,
.services_wrapper .card p{
    color:var(--text-white);
}
.services_wrapper .icon-box{
    margin-bottom:1.875rem;
}
 
/*7 Team Section */
.team_wrapper .card{
    padding:1.25rem;
}
.team_wrapper .card:hover{
    -webkit-transform:translateY(-0.625rem);
    transform:translateY(-0.625rem);
}
.team_wrapper .team-img{
    margin:0 auto;
}
.team_wrapper .team-info .social-network{
    -webkit-transition:all 0.3s ease-in-out;
    transition:all 0.3s ease-in-out;
    padding:.625rem 0;
    list-style: none;
    display: flex;
    align-items: center;
    justify-content: center;
}
.team_wrapper .team-info .social-network li a{
    width:1.5625rem;
    height:1.5625rem;
    line-height: 1.5625rem;
    display: block;
    border-radius: .3125rem;
    margin: 0 .3125rem;
    font-size: .75rem;
    background-color:var(--bg-dark);
    color:var(--text-white);
    -webkit-transition:all 0.3s ease;
    transition:all 0.3s ease;
}
.team_wrapper .team-info .social-network li a:hover{
    background-color:var(--secondary-color);
} 

/*8 Gallery Section */
.gallery_wrapper{
    background-image:url('./images/gallery/gallery-background.png');
    background-repeat: no-repeat;
    background-position:center;
    background-size:cover;
}
.gallery_wrapper img{
    border-radius: .9375rem;
}


/* 7 Pricing */
.price_wrapper{
    padding:100px 0 0;
}
.price_wrapper .pricing_subtitle{ 
    font-size: 20px;
    font-weight: 700;
    line-height: 1.5;
    margin-bottom: 5px;
    color: var(--text-light-3);
}
.price_wrapper .pricing_title{ 
    font-family: var(--secondary-font);
    font-weight: 800;
    font-size: 34px;
    line-height: 1.25;
    margin-bottom: 25px;
    color: var(--text-black);
} 
.price_wrapper .pricing_card {
    background-color: var(--bg-white);
    box-shadow: 0 1px 20px rgb(0 0 0 / 15%);
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 40px 40px 45px 40px;
    border-radius: 10px;
    text-align: center;
    margin-bottom:30px;
}
.price_wrapper .pricing_card:hover {
    box-shadow: 0 2px 29px rgb(0 0 0 / 20%);
    z-index: 5;
    cursor: pointer;
}
.price_wrapper .pricing_period {
    font-family: var(--secondary-font);
    font-size: 22px;
    font-weight: 600;
    line-height: 1.5;
    padding-bottom: 0px;
    color: var(--text-light-2);
}
.price_wrapper .pricing_card img{
    max-width: 75%;
}
.price_wrapper .pricing_rate {
    color: var(--text-black);
    font-weight: 800;
    font-family: var(--secondary-font);
    font-size: 34px;
    line-height: 1.25;
}
.price_wrapper .pricing_text {
    font-size: 14px;
    font-family: var(--secondary-font);
    font-weight: 600;
    color:var(--text-black);
    margin:0;
}
.price_wrapper .pricing_all_plan {
    text-align: center;
    width: 100%;
    display: flex;
    margin: 15px 0;
}
.price_wrapper .pricing_all_plan ul {
    width: 100%;
    list-style: none;
    padding-inline-start: 0;
}
.price_wrapper .pricing_all_plan li {
    font-size: 18px;
    color: var(--text-dark-gray); 
    font-weight: 500;
    line-height: 1.25;
    text-align: center;
    margin-top: 5px;
}
.price_wrapper .pricing_all_plan p {
    font-size: 13px;
    color: var(--text-dark-gray); 
    font-weight: 400;
    line-height: 1.25;
    text-align: center;
    margin-top: 5px;
}
.price_wrapper .pricing_all_plan li strike {
    color:var(--text-light-4);
}
.price_wrapper .learn-more-btn{
    background:var(--bg-dark-green);
    border-color:var(--bg-dark-green);
}
.price_wrapper .featured {
    box-shadow: 0 2px 40px rgb(0 0 0 / 5%);
    background-color: var(--bg-white);
    border: 5px solid var(--text-black);
    padding-top: 80px;
    padding-bottom: 80px;
}
.price_wrapper .pricing-ribbon {
    width: 120px;
    height: 120px;
    overflow: hidden;
    position: absolute;
    top: 0;
    left: 20;
}
.price_wrapper .pricing-ribbon span {
    font-family: var(--secondary-font);
    position: absolute;
    display: block;
    width: 225px;
    padding: 5px 0;
    background-color: black;
    color:var(--text-white);
    font-size: 15px;
    font-weight: 600;
    text-shadow: 0 1px 1px rgb(0 0 0 / 20%);
    text-transform: uppercase;
    text-align: center;
    right: -45px;
    top: 30px;
    transform: rotate(-45deg);
}
.price_wrapper .btn-featured {
    background-color: var(--bg-black);
    border-color:var(--bg-black);
}
.price_wrapper .featured .pricing_rate {
    font-size: 40px;
}
.free_trial{
    background:var(--bg-light-2);
    padding:80px 0;
    margin-top:100px;
}
.free_trial .free_title{
    font-family: var(--secondary-font);
    font-weight: 800;
    font-size: 34px;
    line-height: 1.25; 
    margin-bottom: 0;
    color: var(--text-black);
}
.free_trial .learn-more-btn{
    background:var(--bg-black);
    border-color:var(--bg-black);
}
.free_trial .learn-more-btn .fa {
    font-size: 22px;
    margin-right: 5px;
    vertical-align: bottom;
    color: var(--text-yellow);
}

/*9 Testimonial */
.testimonial_wrapper .card{
    background-color:rgba(252, 81, 133, .05);
}  

/* appointment */
.appointment_wrapper {
    background-image: url('./images/appointmnet-bg.jpg');
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
}

/*10 blog */
.blog_wrapper .blog-content{
    background-color:var(--bg-light-blue);
    padding:2.5rem;
}

/* 10 FAQs */
.faq_wrapper{
    padding:100px 0 0;
}
.faq_wrapper .faq_subtitle{ 
    font-size: 20px;
    font-weight: 700;
    line-height: 1.5;
    margin-bottom: 5px;
    color: var(--text-light-3);
}
.faq_wrapper .faq_title{
    font-family: var(--secondary-font);
    font-weight: 800;
    font-size: 34px;
    line-height: 1.25;
    margin-bottom: 25px;
    color: var(--text-black);
}
.faq_wrapper .accordion-button:hover,
.faq_wrapper .accordion-button:focus{
    box-shadow: none;
    color:var(--secondary-color);
}
.faq_wrapper .accordion-item{
    border:0;
}
.faq_wrapper .accordion-button{
    font-size: 21px;
    font-family: var(--secondary-font);
    font-weight: 600;
    color: var(--text-black);
    background-color: var(--text-white);
    border-radius: 0;
    padding: 0 0 15px 0;
    border-bottom: 1px solid var(--primary-text);
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 15px;
}
.faq_wrapper .accordion-button::after{
    content:'\002B';
    font-family: "Font Awesome 5 Free";
    font-weight: 800;
    display: inline-block;
    background-image: none;
}
.faq_wrapper .accordion-button:not(.collapsed)::after{
    content:"\2212";
    font-family: "Font Awesome 5 Free";
    font-weight: 800;
    display: inline-block;
    background-image: none;
    transform:unset;
}
.faq_wrapper .accordion-body{
    color: var(--text-black); 
    font-size: 15px;
    font-weight: 600;
    margin: 0 0 20px 0;
    padding-bottom: 25px;
    line-height: 1.5; 
}


/* 11 footer */
.footer_wrapper{
    background-color:var(--primary-color);
    padding-bottom:0;
}
.footer_wrapper h5{
    color:var(--text-white);
}
.footer_wrapper .contact-info ul a {
    padding:0;
}
.footer_wrapper ul li{
    margin-bottom:.5rem;
    list-style: none;
    font-size: .875rem;
}
.footer_wrapper ul li a{
    color:var(--text-white);
}
.footer_wrapper .link-widget li a,
.footer_wrapper p{
    color:var(--text-white);
    font-size: .875rem;
    padding-left:1.5rem;
    position:relative;
    -webkit-transition:all 0.3s ease-out 0s;
    transition:all 0.3s ease-out 0s;
}
.footer_wrapper .link-widget li a::before{
    content:'\f105';
    font-family: "Font Awesome 5 Free";
    font-weight: 600;
    position:absolute;
    left:0.3rem;
    top:50%;
    -webkit-transform:translateY(-50%);
    transform:translateY(-50%);
}
.footer_wrapper .link-widget li a:hover{
    margin-left:.625rem;
    color:var(--secondary-text);
}
.footer_wrapper .social-network a{
    width:2.1875rem;
    height:2.1875rem;
    margin:.5rem;
    line-height: 2rem;
    font-size: .875rem;
    display:inline-block;
    border:.125rem solid var(--bg-light-blue);
    color:var(--text-light);
    text-align: center;
    border-radius: 100%;
    -webkit-transition:all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
    transition:all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
}
.footer_wrapper .social-network a:hover{
    background-color:var(--secondary-color);
    border-color:var(--secondary-color);
    color:var(--text-white);
    transform:translateY(-0.1875rem);
}
.footer_wrapper ::placeholder{
    color:var(--text-light);
}
.footer_wrapper .form-control{
    color:var(--text-light);
}
.footer_wrapper .form-control:focus{
    outline:none;
    box-shadow: none;
    border-color:var(--bg-white);
}
.footer_wrapper .copyright-section{
    background-color:var(--bg-light-blue);
    padding:2rem 0 1rem;
    text-align: center;
}
.footer_wrapper .copyright-section a{
    color:var(--text-white);
    font-weight: 600;
}

/* <!--Responsive design--> */
@media (max-width: 1199.5px) {

    /* custom css */ 
    .card{
        padding:2.5rem 1.875rem;
    } 

    /* header */ 
    .header_wrapper .navbar-nav .nav-link{
        font-size: 1rem;
    }

    /* top banner */ 
    .banner_wrapper .animate-img .aimg1{
        left:-3.125rem;
    }

    /* about */ 
    .about_wrapper .card{
        width:100%;
    } 
    .about_wrapper .about-animate{
        left:.9375rem;
    }

    /* service */ 
    .services_wrapper .card{
        padding:1.875rem 1.25rem;
    }

}

@media (max-width:991px) {

    /* custom css */ 
    .wrapper{
        padding:3.125rem 0;
    }
    .main-btn{
        font-size: .875rem;
    }
    h1{
        font-size: 2.8125rem;
        margin-bottom:.625rem;
    }
    h2{
        font-size: 1.875rem; 
    }
    h3{
        font-size: 1.5625rem; 
    }
    h4{
        font-size: 1.25rem; 
    }
    h5{
        font-size: 1.125rem; 
    }
    p{
        font-size: .875rem;
        line-height: 1.35rem;
    }
    .card{
        padding:1.875rem 1rem;
    }

    /* 2 Navbar */ 
    .header-scrolled{
        height:auto;
    }
    .header_wrapper .menu-navbar-nav{
        text-align: center;
    }
    .header_wrapper .nav-item .nav-link{
        margin-top:.9375rem;
    }

    /* banner */ 
    .banner_wrapper p{
        max-width: 100%;
    }


    /* about */ 
    .about_wrapper .about-clinic:nth-child(1),
    .about_wrapper .about-clinic:nth-child(2){
        margin-bottom:1.5625rem;
    }
    .about_wrapper .about-clinic:nth-child(2),
    .about_wrapper .about-clinic:nth-child(4){
        padding-left:2.375rem;
    }

    /* services */ 
    .services_wrapper .card{
        padding:1.875rem .875rem;
    }

    /* blog */ 
    .blog_wrapper .card .blog-content{
        padding:1.875rem .625rem;
    }

}

@media (max-width: 767px) {

    /* custom css */ 
    .main-btn{
        font-size: .875rem;
    }
    h1{
        font-size: 2.5rem;
        margin-bottom:2.5rem;
    } 
    h5{
        font-size: 1.25rem; 
    } 

    /* service */ 
    .services_wrapper .card{
        text-align: center;
    }

    /* blog */ 
    .blog_wrapper .card .blog-content{
        margin:0;
    }

    /* footer */ 
    .footer_wrapper{ 
        padding-bottom:0;
    }
    .footer_wrapper>div{
        padding:0 1.875rem;
    }
}

@media (max-width: 360px) {

    /* Services */ 
    .services_wrapper .icon-box{
        margin-bottom:.9375rem;
    }

    /* about */   
    .about_wrapper .about-animate{
        width:6.875rem;
    }
    .about_wrapper .card{
        padding:1.25rem .3125rem;
    }
    .about_wrapper .about-clinic:nth-child(2),
    .about_wrapper .about-clinic:nth-child(4){
        padding-left:.625rem;
    }

}
      