.call-logo-container {
    position: fixed;
    left: 10px; /* Adjust the spacing from the left as needed */
    bottom: 10px; /* Adjust the spacing from the bottom as needed */
    z-index: 1000; /* High z-index to ensure it's above other content */
  }
  
  .call-logo-link {
    display: flex;
    align-items: center; /* Aligns text and icon vertically */
    text-decoration: none; /* Removes underline from link */
  }
  
  .call-logo {
    width: 50px; /* Adjust the size as needed */
    height: auto; /* Maintain aspect ratio */
    transition: opacity 0.3s ease;
  }
  
  .call-text {
    margin-left: 10px; /* Space between icon and text */
    color: #fff; /* Set the color of the text */
    font-size: 16px; /* Adjust the font size as needed */
    background-color: black;
    padding: 3px;
  }
  
  .call-logo-container:hover .call-logo {
    opacity: 0.7; /* Slightly transparent on hover */
  }
  